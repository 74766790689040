import { FormFieldDataType } from '../../../common/src/lib/kronos-data';

export const commonEnv = {
  newCustomerId: '554007',
  infoDashboard: {
    title: 'Wissenszentrale für gemeinsamen Erfolg.',
  },
  formValidators: [
    {
      name: 'Email',
      regex: '^.+@.+\\..+$',
    },
    {
      name: 'Telefon',
      regex:
        '^(\\+\\s?\\d)?\\d\\s?\\d\\s?\\d\\s?\\d\\s?\\d\\s?\\d\\s?\\d\\s?\\d\\s?\\d\\s?\\d$',
    },
    {
      name: 'AHV Nummer',
      regex: '^[\\d]{3}\\.[\\d]{4}\\.[\\d]{4}\\.[\\d]{2}$',
    },
    {
      name: 'Datum',
      regex:
        '^(?:(?:31(\\/|-|\\.)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(\\/|-|\\.)(?:0?[13-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$|^(?:29(\\/|-|\\.)0?2\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\\d|2[0-8])(\\/|-|\\.)(?:(?:0?[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$',
    },
  ],
  autoCompleteOptions: [
    {
      fieldTypes: [FormFieldDataType.Text],
      name: 'Name',
      value: 'name',
    },
    {
      fieldTypes: [FormFieldDataType.Text],
      name: 'Vorname',
      value: 'given-name',
    },
    {
      fieldTypes: [FormFieldDataType.Text],
      name: 'Nachname',
      value: 'family-name',
    },
    {
      fieldTypes: [FormFieldDataType.Text],
      name: 'Anrede',
      value: 'honorific-prefix',
    },
    {
      fieldTypes: [FormFieldDataType.Text],
      name: 'Namenszusatz',
      value: 'honorific-suffix',
    },
    {
      fieldTypes: [FormFieldDataType.Text],
      name: 'Geschlecht',
      value: 'sex',
    },
    {
      fieldTypes: [FormFieldDataType.Text],
      name: 'Geburtstag',
      value: 'bday',
    },
    {
      fieldTypes: [FormFieldDataType.Text],
      name: 'Sprache',
      value: 'language',
    },
    {
      fieldTypes: [FormFieldDataType.Text],
      name: 'Email',
      value: 'email',
    },
    {
      fieldTypes: [FormFieldDataType.Text],
      name: 'Telefon',
      value: 'tel',
    },
    {
      fieldTypes: [FormFieldDataType.Text],
      name: 'Webseite',
      value: 'url',
    },
    {
      fieldTypes: [FormFieldDataType.Text],
      name: 'Benutzername',
      value: 'username',
    },
    {
      fieldTypes: [FormFieldDataType.Text],
      name: 'Firma',
      value: 'organization',
    },
    {
      fieldTypes: [FormFieldDataType.Text],
      name: 'Adresse',
      value: 'street-address',
    },
    {
      fieldTypes: [FormFieldDataType.Text],
      name: 'PLZ',
      value: 'postal-code',
    },
    {
      fieldTypes: [FormFieldDataType.Text],
      name: 'Land',
      value: 'country',
    },
  ],
  chatFileUploadWhiteList:
    'image/jpeg, image/gif, image/png, application/pdf, video/mp4, video/quicktime',
  chatFileUploadWhiteListMessage: '.jpeg, .jpg, .png, .gif, .pdf, .mp4, .mov',
};
