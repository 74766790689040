/* tslint:disable */
/**
* Kronos Api
* No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
*
* OpenAPI spec version: v1
* 
*
* NOTE: This class is auto generated by the swagger code generator program.
* https://github.com/swagger-api/swagger-codegen.git
* Do not edit the class manually.
*/


import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ArchivedMediaType } from '../model/archivedMediaType';
import { UploadPolicy } from '../model/uploadPolicy';
import { ZulipChannelArchivedMediaDto } from '../model/zulipChannelArchivedMediaDto';
import { ZulipChannelDto } from '../model/zulipChannelDto';
import { ZulipCredentialsDto } from '../model/zulipCredentialsDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ZulipService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param channelId 
     * @param body 
     * @param announce 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addParticipants(channelId: string, body?: Array<string>, announce?: boolean, observe?: 'body', reportProgress?: boolean): Promise<ZulipChannelDto>;
    public addParticipants(channelId: string, body?: Array<string>, announce?: boolean, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<ZulipChannelDto>>;
    public addParticipants(channelId: string, body?: Array<string>, announce?: boolean, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<ZulipChannelDto>>;
    public addParticipants(channelId: string, body?: Array<string>, announce?: boolean, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (channelId === null || channelId === undefined) {
            throw new Error('Required parameter channelId was null or undefined when calling addParticipants.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (announce !== undefined && announce !== null) {
            queryParameters = queryParameters.set('announce', <any>announce);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.patch(`${this.basePath}/api/zulip/channels/${encodeURIComponent(String(channelId))}/add`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param zulipChannelId 
     * @param type 
     * @param messageId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public archiveMedia(zulipChannelId?: string, type?: ArchivedMediaType, messageId?: number, observe?: 'body', reportProgress?: boolean): Promise<ZulipChannelArchivedMediaDto>;
    public archiveMedia(zulipChannelId?: string, type?: ArchivedMediaType, messageId?: number, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<ZulipChannelArchivedMediaDto>>;
    public archiveMedia(zulipChannelId?: string, type?: ArchivedMediaType, messageId?: number, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<ZulipChannelArchivedMediaDto>>;
    public archiveMedia(zulipChannelId?: string, type?: ArchivedMediaType, messageId?: number, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (zulipChannelId !== undefined && zulipChannelId !== null) {
            queryParameters = queryParameters.set('ZulipChannelId', <any>zulipChannelId);
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('Type', <any>type);
        }
        if (messageId !== undefined && messageId !== null) {
            queryParameters = queryParameters.set('MessageId', <any>messageId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/zulip/channels/media/archive`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createGroupChannel(body?: Array<string>, observe?: 'body', reportProgress?: boolean): Promise<ZulipChannelDto>;
    public createGroupChannel(body?: Array<string>, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<ZulipChannelDto>>;
    public createGroupChannel(body?: Array<string>, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<ZulipChannelDto>>;
    public createGroupChannel(body?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

    return this.httpClient.post(`${this.basePath}/api/zulip/channels/create-group`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param fileName 
     * @param folderName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ensureAttachmentHeader(fileName: string, folderName: string, observe?: 'body', reportProgress?: boolean): Promise<any>;
    public ensureAttachmentHeader(fileName: string, folderName: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<any>>;
    public ensureAttachmentHeader(fileName: string, folderName: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<any>>;
    public ensureAttachmentHeader(fileName: string, folderName: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (fileName === null || fileName === undefined) {
            throw new Error('Required parameter fileName was null or undefined when calling ensureAttachmentHeader.');
        }
        if (folderName === null || folderName === undefined) {
            throw new Error('Required parameter folderName was null or undefined when calling ensureAttachmentHeader.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/zulip/${encodeURIComponent(String(fileName))}/${encodeURIComponent(String(folderName))}/ensureAttachmentHeader`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public favorite(name: string, observe?: 'body', reportProgress?: boolean): Promise<any>;
    public favorite(name: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<any>>;
    public favorite(name: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<any>>;
    public favorite(name: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling favorite.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/zulip/${encodeURIComponent(String(name))}/favorite`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param channelId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getArchivedMessagesForChannel(channelId: string, observe?: 'body', reportProgress?: boolean): Promise<Array<ZulipChannelArchivedMediaDto>>;
    public getArchivedMessagesForChannel(channelId: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<Array<ZulipChannelArchivedMediaDto>>>;
    public getArchivedMessagesForChannel(channelId: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<Array<ZulipChannelArchivedMediaDto>>>;
    public getArchivedMessagesForChannel(channelId: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (channelId === null || channelId === undefined) {
            throw new Error('Required parameter channelId was null or undefined when calling getArchivedMessagesForChannel.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/zulip/channels/${encodeURIComponent(String(channelId))}/archived`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param channelId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChannel(channelId: string, observe?: 'body', reportProgress?: boolean): Promise<ZulipChannelDto>;
    public getChannel(channelId: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<ZulipChannelDto>>;
    public getChannel(channelId: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<ZulipChannelDto>>;
    public getChannel(channelId: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (channelId === null || channelId === undefined) {
            throw new Error('Required parameter channelId was null or undefined when calling getChannel.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/zulip/channels/${encodeURIComponent(String(channelId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChannelById(id: string, observe?: 'body', reportProgress?: boolean): Promise<ZulipChannelDto>;
    public getChannelById(id: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<ZulipChannelDto>>;
    public getChannelById(id: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<ZulipChannelDto>>;
    public getChannelById(id: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getChannelById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/zulip/channels/byId/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param channelName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChannelByName(channelName: string, observe?: 'body', reportProgress?: boolean): Promise<ZulipChannelDto>;
    public getChannelByName(channelName: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<ZulipChannelDto>>;
    public getChannelByName(channelName: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<ZulipChannelDto>>;
    public getChannelByName(channelName: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (channelName === null || channelName === undefined) {
            throw new Error('Required parameter channelName was null or undefined when calling getChannelByName.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/zulip/channels/byname/${encodeURIComponent(String(channelName))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param customerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChannelForCustomer(customerId: string, observe?: 'body', reportProgress?: boolean): Promise<ZulipChannelDto>;
    public getChannelForCustomer(customerId: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<ZulipChannelDto>>;
    public getChannelForCustomer(customerId: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<ZulipChannelDto>>;
    public getChannelForCustomer(customerId: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling getChannelForCustomer.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/zulip/channels/bycustomer/${encodeURIComponent(String(customerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param projectGuid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChannelForProject(projectGuid: string, observe?: 'body', reportProgress?: boolean): Promise<ZulipChannelDto>;
    public getChannelForProject(projectGuid: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<ZulipChannelDto>>;
    public getChannelForProject(projectGuid: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<ZulipChannelDto>>;
    public getChannelForProject(projectGuid: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (projectGuid === null || projectGuid === undefined) {
            throw new Error('Required parameter projectGuid was null or undefined when calling getChannelForProject.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/zulip/channels/byproject/${encodeURIComponent(String(projectGuid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param supplierId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChannelForSupplier(supplierId: string, observe?: 'body', reportProgress?: boolean): Promise<ZulipChannelDto>;
    public getChannelForSupplier(supplierId: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<ZulipChannelDto>>;
    public getChannelForSupplier(supplierId: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<ZulipChannelDto>>;
    public getChannelForSupplier(supplierId: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getChannelForSupplier.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/zulip/channels/bysupplier/${encodeURIComponent(String(supplierId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param zulipIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChannelsByZulipIds(zulipIds?: Array<number>, observe?: 'body', reportProgress?: boolean): Promise<Array<ZulipChannelDto>>;
    public getChannelsByZulipIds(zulipIds?: Array<number>, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<Array<ZulipChannelDto>>>;
    public getChannelsByZulipIds(zulipIds?: Array<number>, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<Array<ZulipChannelDto>>>;
    public getChannelsByZulipIds(zulipIds?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (zulipIds) {
            zulipIds.forEach((element) => {
                queryParameters = queryParameters.append('zulipIds', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/zulip/channels`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFavorites(observe?: 'body', reportProgress?: boolean): Promise<Array<ZulipChannelDto>>;
    public getFavorites(observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<Array<ZulipChannelDto>>>;
    public getFavorites(observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<Array<ZulipChannelDto>>>;
    public getFavorites(observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/zulip/favorites`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param path 
     * @param token 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUploadedImagePath(path?: string, token?: string, observe?: 'body', reportProgress?: boolean): Promise<any>;
    public getUploadedImagePath(path?: string, token?: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<any>>;
    public getUploadedImagePath(path?: string, token?: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<any>>;
    public getUploadedImagePath(path?: string, token?: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (path !== undefined && path !== null) {
            queryParameters = queryParameters.set('path', <any>path);
        }
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/zulip/uploads`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getZulipPasswordForCurrentUser(observe?: 'body', reportProgress?: boolean): Promise<ZulipCredentialsDto>;
    public getZulipPasswordForCurrentUser(observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<ZulipCredentialsDto>>;
    public getZulipPasswordForCurrentUser(observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<ZulipCredentialsDto>>;
    public getZulipPasswordForCurrentUser(observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.get(`${this.basePath}/api/zulip/current-user-password`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param partnerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public initiateDirect(partnerId: string, observe?: 'body', reportProgress?: boolean): Promise<ZulipChannelDto>;
    public initiateDirect(partnerId: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<ZulipChannelDto>>;
    public initiateDirect(partnerId: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<ZulipChannelDto>>;
    public initiateDirect(partnerId: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (partnerId === null || partnerId === undefined) {
            throw new Error('Required parameter partnerId was null or undefined when calling initiateDirect.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/zulip/direct/${encodeURIComponent(String(partnerId))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param channelId 
     * @param newName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public renameChannel(channelId: string, newName?: string, observe?: 'body', reportProgress?: boolean): Promise<ZulipChannelDto>;
    public renameChannel(channelId: string, newName?: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<ZulipChannelDto>>;
    public renameChannel(channelId: string, newName?: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<ZulipChannelDto>>;
    public renameChannel(channelId: string, newName?: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (channelId === null || channelId === undefined) {
            throw new Error('Required parameter channelId was null or undefined when calling renameChannel.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (newName !== undefined && newName !== null) {
            queryParameters = queryParameters.set('newName', <any>newName);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/zulip/channels/${encodeURIComponent(String(channelId))}/rename`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param channelId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public spawnChannel(channelId: string, observe?: 'body', reportProgress?: boolean): Promise<ZulipChannelDto>;
    public spawnChannel(channelId: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<ZulipChannelDto>>;
    public spawnChannel(channelId: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<ZulipChannelDto>>;
    public spawnChannel(channelId: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (channelId === null || channelId === undefined) {
            throw new Error('Required parameter channelId was null or undefined when calling spawnChannel.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/zulip/${encodeURIComponent(String(channelId))}/spawn`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param channelId 
     * @param announce 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public subscribeToChannel(channelId: string, announce?: boolean, observe?: 'body', reportProgress?: boolean): Promise<ZulipChannelDto>;
    public subscribeToChannel(channelId: string, announce?: boolean, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<ZulipChannelDto>>;
    public subscribeToChannel(channelId: string, announce?: boolean, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<ZulipChannelDto>>;
    public subscribeToChannel(channelId: string, announce?: boolean, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (channelId === null || channelId === undefined) {
            throw new Error('Required parameter channelId was null or undefined when calling subscribeToChannel.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (announce !== undefined && announce !== null) {
            queryParameters = queryParameters.set('announce', <any>announce);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/zulip/channels/${encodeURIComponent(String(channelId))}/subscribe`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param zulipChannelId 
     * @param messageId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unArchiveMedia(zulipChannelId?: string, messageId?: number, observe?: 'body', reportProgress?: boolean): Promise<any>;
    public unArchiveMedia(zulipChannelId?: string, messageId?: number, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<any>>;
    public unArchiveMedia(zulipChannelId?: string, messageId?: number, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<any>>;
    public unArchiveMedia(zulipChannelId?: string, messageId?: number, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (zulipChannelId !== undefined && zulipChannelId !== null) {
            queryParameters = queryParameters.set('ZulipChannelId', <any>zulipChannelId);
        }
        if (messageId !== undefined && messageId !== null) {
            queryParameters = queryParameters.set('MessageId', <any>messageId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/zulip/channels/media/unArchive`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unfavorite(name: string, observe?: 'body', reportProgress?: boolean): Promise<any>;
    public unfavorite(name: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<any>>;
    public unfavorite(name: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<any>>;
    public unfavorite(name: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling unfavorite.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/zulip/${encodeURIComponent(String(name))}/unfavorite`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param channelId 
     * @param announce 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unsubscribeFromChannel(channelId: string, announce?: boolean, observe?: 'body', reportProgress?: boolean): Promise<ZulipChannelDto>;
    public unsubscribeFromChannel(channelId: string, announce?: boolean, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<ZulipChannelDto>>;
    public unsubscribeFromChannel(channelId: string, announce?: boolean, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<ZulipChannelDto>>;
    public unsubscribeFromChannel(channelId: string, announce?: boolean, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {
        if (channelId === null || channelId === undefined) {
            throw new Error('Required parameter channelId was null or undefined when calling unsubscribeFromChannel.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (announce !== undefined && announce !== null) {
            queryParameters = queryParameters.set('announce', <any>announce);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.delete(`${this.basePath}/api/zulip/channels/${encodeURIComponent(String(channelId))}/unsubscribe`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

    /**
     * 
     * 
     * @param path 
     * @param previewPath 
     * @param contentType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadPreview(path?: string, previewPath?: string, contentType?: string, observe?: 'body', reportProgress?: boolean): Promise<UploadPolicy>;
    public uploadPreview(path?: string, previewPath?: string, contentType?: string, observe?: 'response', reportProgress?: boolean): Promise<HttpResponse<UploadPolicy>>;
    public uploadPreview(path?: string, previewPath?: string, contentType?: string, observe?: 'events', reportProgress?: boolean): Promise<HttpEvent<UploadPolicy>>;
    public uploadPreview(path?: string, previewPath?: string, contentType?: string, observe: any = 'body', reportProgress: boolean = false ): Promise<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (path !== undefined && path !== null) {
            queryParameters = queryParameters.set('path', <any>path);
        }
        if (previewPath !== undefined && previewPath !== null) {
            queryParameters = queryParameters.set('previewPath', <any>previewPath);
        }
        if (contentType !== undefined && contentType !== null) {
            queryParameters = queryParameters.set('contentType', <any>contentType);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

    return this.httpClient.post(`${this.basePath}/api/zulip/uploadPreview`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).toPromise();
    }

}
