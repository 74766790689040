


export type ZulipChannelType = 'Direct' | 'Group' | 'Project' | 'News' | 'Info' | 'Customer' | 'Supplier' | 'Private' | 'ExternalCustomer';

export const ZulipChannelType = {
    Direct: 'Direct' as ZulipChannelType,
    Group: 'Group' as ZulipChannelType,
    Project: 'Project' as ZulipChannelType,
    News: 'News' as ZulipChannelType,
    Info: 'Info' as ZulipChannelType,
    Customer: 'Customer' as ZulipChannelType,
    Supplier: 'Supplier' as ZulipChannelType,
    Private: 'Private' as ZulipChannelType,
    ExternalCustomer: 'ExternalCustomer' as ZulipChannelType
};

